<template>
  <b-modal
    v-model="modalShow"
    size="sm"
    centered
    title="Mark Pickup Verified"
    @hidden="$emit('modal-closed')"
  >
    <div>
      <b-form @submit.prevent="submitHandler">
        <b-form-group
          label="New Bucket No."
        >
          <b-form-input
            v-model="newBucketNumber"
            type="text"
          />
        </b-form-group>
      </b-form>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="!newBucketNumber || submitting"
        @click="submitHandler"
      >
        Submit
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSpinner,
    BFormGroup,
    BForm,
    BFormInput,
  },
  props: {
    rgReportIds: {
      type: Array,
      required: true,
    },
    bucketNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      newBucketNumber: null,
      submitting: false,
    }
  },
  created() {
    this.newBucketNumber = this.bucketNumber
  },
  methods: {
    submitHandler() {
      if (!this.newBucketNumber) {
        return
      }

      this.submitting = true
      axios.post('operations/verify-pickup', {
        rg_report_ids: this.rgReportIds,
        new_bucket_number: this.newBucketNumber,
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('verified')
        this.modalShow = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Error updating status!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.submitting = false
      })
    },
  },
}
</script>
