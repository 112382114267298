<template>
  <div class="invoice-items">
    <b-card no-body>
      <div @click="expanded = !expanded">
        <div class="d-flex align-items-center text-primary font-weight-bold cursor-pointer">
          <feather-icon
            v-if="expanded"
            icon="ChevronDownIcon"
            size="20"
          />
          <feather-icon
            v-else
            icon="ChevronRightIcon"
            size="20"
          />
          <div>
            {{ title }} ({{ items.length }})
          </div>
        </div>
      </div>

      <b-card-text
        v-if="expanded"
        class="mt-50"
      >
        <b-table
          striped
          hover
          class="compact-table"
          :fields="tableColumns"
          :items="items"
          :tbody-tr-class="rowClass"
        >
          <template #cell(quantity)="data">
            {{ data.item.quantity == data.item.picked_quantity ? data.item.quantity : '--' }}
          </template>
        </b-table>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BTable, BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCard,
    BCardText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expanded: true,
      tableColumns: ['batch', 'product', 'pack', 'quantity', 'picked_quantity', 'company', 'rack_no', 'rack_group_no', 'expiry', 'rate', 'mrp'],
    }
  },
  methods: {
    rowClass(item, type) {
      let className = ''
      if (!item && type === 'row') {
        return className
      }

      if (item.quantity === item.picked_quantity) {
        className = 'row-completed'
      } else if (item.picked_quantity !== 0) {
        className = ' row-picked'
      }

      return className
    },
  },
}
</script>

<style scoped lang="scss">
.invoice-items {
  ::v-deep .row-picked {
    background-color: #FFF176;
  }
  ::v-deep .table-striped tbody tr.row-picked:nth-of-type(odd) {
    background-color: #FFEE58;
  }
  ::v-deep .table-hover tbody tr.row-picked:hover {
    background-color: #FFD600;
  }

  ::v-deep .row-completed {
    background-color: #C8E6C9;
  }
  ::v-deep .table-striped tbody tr.row-completed:nth-of-type(odd) {
    background-color: #B8E0B9;
  }
  ::v-deep .table-hover tbody tr.row-completed:hover {
    background-color: #81C784;
  }
}
</style>
