<template>
  <div class="outer-wrapper">
    <div
      class="border rounded text-white item-holder"
      :class="itemClasses"
    >
      <b-form-checkbox
        v-model="isSelected"
        :disabled="readOnly || statusVariant === 'success' || rgReport.invoice__created_from_challan"
      />
      <span
        class="group-number cursor-pointer"
        @click="displayDetails = true"
      >#{{ rgReport.rack_group_no }}</span>

      <b-form @submit.prevent="updateBucketNumber">
        <b-form-input
          v-model="bucketNumber"
          type="text"
          :disabled="readOnly || statusVariant === 'success' || submitting || rgReport.invoice__created_from_challan"
        />
      </b-form>
    </div>
    <span
      v-if="rgReport.attention_required===true"
      class="attention-required"
    />
    <span
      v-else-if="rgReport.attention_required===false"
      class="attention-completed"
    />

    <r-g-report-details-modal
      v-if="displayDetails"
      :id="rgReport.id"
      :read-only="readOnly"
      @modal-closed="displayDetails = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { BFormInput, BFormCheckbox, BForm } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RGReportDetailsModal from '@/components/Operations/RackGroupReports/RGReportDetailsModal.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormCheckbox,
    RGReportDetailsModal,
  },
  props: {
    rgReport: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      bucketNumber: null,
      submitting: false,
      displayDetails: false,
    }
  },
  computed: {
    serverBucketNumber() {
      return this.rgReport.pickup_bucket_number
    },
    status: {
      get() {
        return this.rgReport.status
      },
      set(newValue) {
        this.rgReport.status = newValue
      },
    },
    statusVariant() {
      switch (this.status) {
        case 'Pickup Verified':
          return 'success'

        case 'Pickup Completed':
          return 'warning'

        case 'Pickup Initiated':
        default:
          return 'secondary'
      }
    },
    itemClasses() {
      let classes = `bg-${this.statusVariant} border-${this.statusVariant}`
      if (this.rgReport.pickup_initiated_at) {
        classes += ' downloaded'
      }
      return classes
    },
    isSelected: {
      get() {
        return this.$store.getters['operations/selectedRackGroups'].includes(this.rgReport.id)
      },
      set(newVal) {
        if (newVal) {
          this.$store.dispatch('operations/selectRackGroups', { ids: [this.rgReport.id] })
        } else {
          this.$store.dispatch('operations/unSelectRackGroups', { ids: [this.rgReport.id] })
        }
      },
    },
  },
  watch: {
    serverBucketNumber() {
      this.bucketNumber = this.serverBucketNumber
    },
  },
  created() {
    this.bucketNumber = this.serverBucketNumber
  },
  methods: {
    async updateBucketNumber() {
      this.submitting = true
      const payload = { id: this.rgReport.id, pickup_bucket_number: this.bucketNumber }

      axios.post('operations/update_rg_bucket_no', payload).then(res => {
        this.status = res.data.status
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Error updating status!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.bucketNumber = this.serverBucketNumber
      }).finally(() => {
        this.submitting = false
      })
    },
  },
}

</script>

<style scoped>

.outer-wrapper{
  display: flex;
  align-items: center;
  width:120px;
}

.item-holder{
  display: flex;
  width:100px;
  align-items: center;
  padding: 1px 5px;
}

.downloaded{
  border-right: 5px solid green !important;
}

.item-holder input{
  height: 1.7rem;
  padding: 2px;
}

.group-number {
  margin-right: 5px;
}

</style>
